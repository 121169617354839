
@use '../Utilities/variables.scss' as fc;

.fincetus-black-color {
  color: fc.$fc-black;
}
fincetus-red-color {
  color: fc.$fc-red;
}
.core-msg {
  color: fc.$fc-red;
}
.heading-second {
  color: fc.$fc-red;
}
.main-heading {
  margin-top: 30px;
}
.gold-tree-img {
  border-radius: 10px;
}

// Loop slider design.
.slider_inner div {
  width: 30%;
  height: 20px;
  border-radius: 5px;
  margin-left: 10px;
  text-align: center;
  padding: 10px;
}
.slider_inner div:nth-of-type(1){ background:#aedaed; }
.slider_inner div:nth-of-type(2){ background:#eee; }
.slider_inner div:nth-of-type(3){ background:#53edfe; }
.slider_inner div:nth-of-type(4){ background:#e3edfe; }
.slider_inner div:nth-of-type(5){ background:#ced; }

.slider{ display:flex; width:100vw; }
.slider_inner{ width: fit-content; display:flex; }

.r_to_l .slider_inner:first-of-type{ animation: reverse-loop 50s -25s linear infinite; }
.r_to_l .slider_inner:last-of-type{ animation: reverse-loop2 50s linear infinite; }

@keyframes reverse-loop {
  0% { transform: translateX(100%); }
  to { transform: translateX(-100%); }
}
@keyframes reverse-loop2 {
  0% { transform: translateX(0%); }
  to { transform: translateX(-200%); }
}

.l_to_r .slider_inner:first-of-type{ animation: loop 50s -25s linear infinite; }
.l_to_r .slider_inner:last-of-type{ animation: loop2 50s linear infinite; }

@keyframes loop {
  0% { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}
@keyframes loop2 {
  0% { transform: translateX(-200%); }
  to { transform: translateX(0); }
}

.home-page {
  .uvc-sub-heading {
    text-align: center;
  }
  .uvc-heading {
    text-align: center;
    color: #333;
  }
  .home-page-our-solutions  {
    .our-solutions-panel {
      a:hover {
        --tw-scale-x: 1.1;
        --tw-scale-y: 1.1;
        transform: translate(20px, 20px rotate(0) skewX(10px) skewY(10px) scaleX(10px) scaleY(10px));
      }
      img {
        border-radius: 10px;
        margin-top: 25px;
        width: 100%;
        transition: transform .2s;
      }      
    }

    .our-solutions-panel img:hover {
      transform: scale(1.1);
    }

    .layzr-bg:not(.layzr-bg-transparent) {
      background-color: rgba(255, 255, 255, .05);
    }
  }
  .home-page-our-solutions {
    .vc_column_container {
      height: 100%;
    }
  }
  .loop-slider {
    overflow: hidden;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    .tag {
      display: flex;
      width: -moz-max-content;
      width: max-content;
      align-items: center;
      gap: 0 .2rem;
      color: #fff;
      font-size: .9rem;
      background-color: #333;
      border-radius: .4rem;
      padding: .7rem 1rem;
      margin-right: 1rem;
      // box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .2), 0 .1rem .5rem rgba(0, 0, 0, .3), 0 .2rem 1.5rem rgba(0, 0, 0, .4);
    }
  }
  .tag-list {
    position: relative;
    width: 100%;
    .fade {
      pointer-events: none;
      background: linear-gradient(90deg, #fff, transparent 30%, transparent 70%, #fff);
      position: absolute;
      inset: 0;
      border-radius: 10px;
    }
  }
  .main-message {
    text-align: left;
    font-size: 24px;
    margin-top: 35px;
  }
}