.precious-metals {
  #ultimate-heading-6785b474a5f33c22 .uvc-heading-spacer.line_with_icon:before,
  #ultimate-heading-6785b474a5f33c22 .uvc-heading-spacer.line_with_icon:after {
      width: 75px;
      border-style: solid;
      border-color: rgb(255,0,0);
      border-bottom-width: 3px;
  }

  #ultimate-heading-6785b474a5f33c22 .uvc-heading-spacer.line_with_icon:before {
      right: 410.5px;
  }

  #ultimate-heading-6785b474a5f33c22 .uvc-heading-spacer.line_with_icon:after {
      left: 410.5px;
  }

  .vc_column_container>.vc_column-inner {
    padding: 25px 25px 0px 25px;
    .fancy-media-wrap img {
      border-radius: 15px;
      transition: transform .2s;
    }
    .fancy-media-wrap img:hover {
      transform: scale(1.1);
    }
  }
  .service-serices-panel {
    margin-top: 0px;
  }
}