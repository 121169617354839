
.main-contacts {
  #ultimate-heading-41795b47803cca619 .uvc-heading-spacer.line_with_icon:before,
  #ultimate-heading-41795b47803cca619 .uvc-heading-spacer.line_with_icon:after {
    width: 75px;
    border-style: solid;
    border-color: rgb(255,0,0);
    border-bottom-width: 3px;
  }

  // #ultimate-heading-41795b47803cca619 .uvc-heading-spacer.line_with_icon:before {
  //   right: 300.5px;
  // }

  // #ultimate-heading-41795b47803cca619 .uvc-heading-spacer.line_with_icon:after {
  //   left: 300.5px;
  // }


  #ultimate-heading-23165b47803cca9cb .uvc-heading-spacer.line_with_icon:before,
  #ultimate-heading-23165b47803cca9cb .uvc-heading-spacer.line_with_icon:after {
    width: 75px;
    border-style: solid;
    border-color: rgb(255,0,0);
    border-bottom-width: 3px;
  }

  // #ultimate-heading-23165b47803cca9cb .uvc-heading-spacer.line_with_icon:before {
  //   right: 300.5px;
  // }

  // #ultimate-heading-23165b47803cca9cb .uvc-heading-spacer.line_with_icon:after {
  //   left: 300.5px;
  // }
  #menu_section {
    margin-top: 100px;
  }
  .contact-submit {
    cursor: pointer;
    width: 120px;
  
    input[type=submit] {
      clip: inherit !important;
      width: 130% !important;
      left: -116px !important;
      opacity: 0 !important;
      cursor: pointer !important;
      z-index: 99;
    }
  }
}