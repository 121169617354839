
.about-us-main-container {
  #ultimate-heading-53645b491253aa6bd .uvc-heading-spacer.line_with_icon:before,
  #ultimate-heading-53645b491253aa6bd .uvc-heading-spacer.line_with_icon:after {
      width: 75px;
      border-style: solid;
      border-color: red;
      border-bottom-width: 3px;
  }

  #ultimate-heading-53645b491253aa6bd .uvc-heading-spacer.line_with_icon:before {
      right: 410.5px;
  }

  #ultimate-heading-53645b491253aa6bd .uvc-heading-spacer.line_with_icon:after {
      left: 410.5px;
  }
  #ultimate-heading-72065b463bf142a26 .uvc-heading-spacer.line_with_icon:before, 
  #ultimate-heading-72065b463bf142a26 .uvc-heading-spacer.line_with_icon:after {
    width: 155px;
  }


  .ceo-message{
    height: 100px;
    position: relative;
    font-size: 28px;
    top: 50px;
    font-weight: 600;
    color:red;
  }

  .ceo-message-container .fancy-media-wrap img {
    width: 60%;
  }

  .our-objective-list li{
    list-style:none;
  }
  .ceo-message-content {
    font-size: 22px;
  }
  .why-fincetus-container {
    width: 100%;
  }
}
