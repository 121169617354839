
.financial-training {
  .vc_custom_1512326540958 {
    padding-bottom: 20px !important;
    float:right;

    h2 {
      font-size: 14px;
    }
    img {      
      // width: 155px;
      height: 150px;
      transition: all .2s ease-in-out;
      border-radius: 10px !important;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: 768px) {
  .financial-training {
    .vc_custom_1512326540958 {     
      img {     
        height: auto;        
      }
    }
  }
}