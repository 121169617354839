.financial-consulting {
  .vc_custom_1512326540958 {
    padding-bottom: 20px !important;
    float:right;

    h2 {
      font-size: 20px;
    }
    img {      
      // width: 155px;
      transition: all .2s ease-in-out;
      border-radius: 10px !important;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
}